import React from 'react'
import { graphql } from 'gatsby'
import Image from 'gatsby-image'
import Layout from '../components/layout'
import { BLOCKS, MARKS } from "@contentful/rich-text-types"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import SEO from "../components/SEO"
import GoogleAd from '../components/GoogleAd'

const adStyle = {
  marginBottom: '15px',
  textAlign: 'center'
}

const ArticleTemplate = ({ data }) => {
  //console.log("DATA:", data)
  const {
    title,
    summary: { summary },
    mainText,
    mainImage,
  } = data.article
  const Bold = ({ children }) => <span className="bold">{children}</span>
  const Text = ({ children }) => <p className="align-center">{children}</p>
  const options = {
    renderMark: {
      [MARKS.BOLD]: text => <Bold>{text}</Bold>,
    },
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,
    },
  }
  //const ad1 = `<script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"></script><!-- IBS-1 --><ins class="adsbygoogle" style="display:block" data-ad-client="ca-pub-4330891925037093" data-ad-slot="9105303408" data-ad-format="auto" data-full-width-responsive="true"></ins><script>(adsbygoogle = window.adsbygoogle || []).push({});</script>`

  return (
    <Layout>
      <SEO title={title} description={summary} />
      <div>
        <h1>{title}</h1>
      </div>
      {/* <div className="adTop" dangerouslySetInnerHTML={{ __html: ad1 }} /> */}
      <GoogleAd
        client="ca-pub-4330891925037093"
        slot="9105303408"
        format="auto"
        responsive="true"
        wrapperDivStyle={adStyle}
      />
      <div>
        {mainImage ?
          <Image fluid={mainImage.fluid} className="mainImg" alt={title} />
          : null
        }
        {documentToReactComponents(mainText.json, options)}
      </div>
      <div className="clear" />
    </Layout>
  )
}

export const query = graphql`
  query($slug: String) {
    article: contentfulArticle(slug: { eq: $slug }) {
      title
      summary {
        summary
      }
      mainText {
        json
      }
      mainImage {
        fluid {
          ...GatsbyContentfulFluid_withWebp
        }
      }
    }
  }
`

export default ArticleTemplate

          // ...GatsbyContentfulFluid_withWebp
